import axios from "axios";




// -------------------------USED APIS--------------------------------
export const LOGIN_URL = "/authenticate/login";
export const STORE_URL = "/nike/PartnerStore";
export const ME_URL = "Nike/me";
export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function PartnerStore(){
  return axios.get(STORE_URL);
}
export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);

}
//---------------------------------------------------------

export const REGISTER_URL = "users/register";
export const REQUEST_PASSWORD_URL = "users/forgot-password";
export const VERIFY_EMAIL_URL = "users/verify-email";
export const UPDATE_USER_URL = "users";
export const RESET_PASSWORD = "users/reset-password";
export const FIND_AGRARIAN_GROUP = "users/findAgrarianGroup";



export function register(name, lastName, email, password, confirmpassword, acceptterms, phonenumber, companyname, jobposition, Id_agrarian_group, Id_District) {
  return axios.post(REGISTER_URL, { name, lastName, email, password, confirmpassword, acceptterms, phonenumber, companyname, jobposition, Id_agrarian_group, Id_District });
}

export function requestPassword(email) {
  return axios.get(`${REQUEST_PASSWORD_URL}?email=${email}`);
}

export function verifyEmail(verificationToken) {
  return axios.get(`${VERIFY_EMAIL_URL}${verificationToken}`);
}

export function updateUser(id, updateUserSchema) {
  return axios.put(`${UPDATE_USER_URL}/${id}`, updateUserSchema);
}

export function resetPassword(data) {
  return axios.post(`${RESET_PASSWORD}`, data)
}



export function findAgrarianGroup(clave){
  return axios.get(`${FIND_AGRARIAN_GROUP}`,{params:{clave:clave}})
}