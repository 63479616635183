import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import { useIntl } from "react-intl";
import { useState } from "react";
import { useEffect } from "react";
export function AsideMenuList({ layoutProps }) {
  //#region text
  const intl = useIntl();
  const getText = (id) => { return intl.formatMessage({ id: id }) }
  var textPage = {
    actions: getText("ASIDE.MENU.ACTIONS"),
    star: getText("ASIDE.MENU.STAR"),
    qrScanner: getText("ASIDE.MENU.QRSCANNER"),
    admin: {
      partnerSetUp: getText("ASIDE.MENU.ADMIN.PARTNER_SET_UP"),
      partnerDoorSetUp: getText("ASIDE.MENU.ADMIN.PARTNER_DOOR_SET_UP"),
      nspPromoSetUp: getText("ASIDE.MENU.ADMIN.NSP_PROMO_SETUP"),
      promoAnalizer: getText("ASIDE.MENU.ADMIN.PROMO_ANALIZER"),
      users: "Users",
      changeLog: "NSP Change Log",
      rewardSetUp: "NSP Promo Set Up"


    }
  }

  //#endregion

  // #region Type User
  const { auth } = useSelector(
    (state) => ({ auth: state.auth }),
    shallowEqual
  );

  const { user } = auth;

  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (user && user.role && user.role.toLowerCase() === "admin") {
      setIsAdmin(true);
    }
  }, [user])

  // #endregion

  // #region Location
  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    var location = window.location.href;
    if (!(location.includes("nspfrontendproduction") || location.includes("memberrewardstool"))) {
      setIsDev(true)
    }
  }, [])

  // #endregion

  const [open, setOpen] = React.useState(false);


  // function handleClickOpen() {
  //   setOpen(true);
  // }

  function handleClose() {
    setOpen(false);
  }

  const location = useLocation();
  // const isntVerification = useCallback(() => {
  //   return !(location.pathname.toLowerCase().includes("/verificar/"))
  // }, [location])

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const getMenuItemActiveList = (urls, hasSubmenu = false) => {
    var response = ""
    urls.forEach(url => {
      if (checkIsActive(location, url)) {
        response = ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      }
    });
    return response;

  }

  // const isValidLocation = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  // }
  // Customers Redux state






  const MenuItem = ({ title, location, onlyAdmin = false, onlydev = false }) => {

    if (!isAdmin && onlyAdmin)
      return null;

    if (!isDev && onlydev)
      return null;

    return (
      <li
        className={`menu-item ${getMenuItemActive(location)}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to={location === "#" ? "/Start/NoAvaiable" : location}>
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{title}</span>

        </NavLink>
      </li>
    )
  }

  const MenuList = ({ title, locations, location, children, onlyAdmin = false, onlydev = false }) => {
    if (!isAdmin && onlyAdmin)
      return null;

    if (!isDev && onlydev)
      return null;

    return (
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActiveList(
          locations,
          true
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className="menu-link menu-toggle" to={location}>
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{title}</span>
          <i className="menu-arrow" />
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            {children}
          </ul>
        </div>
      </li>
    )
  }
  const MenuListItem = ({ title, location }) => {
    return (
      <li
        className={`menu-item ${getMenuItemActive(
          location
        )}`}
        aria-haspopup="true"
      >
        <NavLink className="menu-link" to={location}>
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{title}</span>
        </NavLink>
      </li>
    )
  }





  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text aside-title">{textPage.actions}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/* Inicio */}

        <MenuItem title={textPage.star} location={"/Start/Index"} />
        <MenuItem title={textPage.qrScanner} location={"/ScanQr"} />
        {/*
        <MenuItem title={textPage.admin.partnerDoorSetUp} location={"#"} />
        <MenuItem title={textPage.admin.nspPromoSetUp} location={"#"} />*/}
        <MenuItem
          title={textPage.admin.partnerSetUp}
          location={"/PartnerSetUp"}
          // onlyAdmin={true}
        // onlydev={true}
        />
        <MenuItem
          title={textPage.admin.partnerDoorSetUp}
          location={"/PartnerDoorSetUp"}
        // onlydev={true}
        />
        <MenuItem
          title={textPage.admin.users}
          location={"/Users"}
        // onlydev={true}
        />
        <MenuItem
          title={textPage.admin.nspPromoSetUp}
          location={"/PromoSetUp"}
        />
        <MenuItem
          title={textPage.admin.promoAnalizer}
          location={"/Analyzer"}
          onlyAdmin={true}
        />
        <MenuItem
          title={textPage.admin.changeLog}
          location={"/ChangeLog"}
          onlyAdmin={true}
        />

        {false &&
          <MenuList title={"Partner Set Up"} locations={["/Admin/PartnerSetUp", "/Users"]} location="/Admin">
            <MenuListItem title={"Partners"} location={"/Admin/PartnerSetUp"} />
            <MenuListItem title={"Users"} location={"/Users"} />
          </MenuList>
        }





        {/*end::1 Level*/}



        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          {/* <h4 className="menu-text"></h4> */}
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Error Pages */}



      </ul>


      {/* end::Menu Nav */}
      {/* Dialog: Logout */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Cerrar sesión"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estás seguro de que quieres cerrar sesión?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Cancelar
            </Button>
            <Button href="/logout" color="secondary" autoFocus>
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
