import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { verifyEmail } from "../_redux/authCrud";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const initialValues = {
  verificationToken: "",
};
var msgOk = "";

function VerifyEmail(props) {
  const { intl } = props;
  var verificationToken = window.location.search;
  const [isRequested, setIsRequested] = useState(false);


  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      verifyEmail(verificationToken)
        .then(({ data: { message } }) => {
          msgOk = message;
          formik.resetForm();
          setIsRequested(false);
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },

  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1 mb-5">Verificar Email</h3>

          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp text-center"
          >
            {!(msgOk !== "" || formik.status) &&
              <button className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 mt-5">
                Verificar
              </button>
            }
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            {/* begin: Alert */}
            {msgOk && (
              <>
                <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                  <div className="alert-text font-weight-bold">{msgOk}</div>
                </div>
                <Link
                  to="/auth/login"
                  className="font-weight-bold ml-2 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </Link>
              </>
            )}
            {/* end: Alert */}

          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(VerifyEmail));
