import axios from "axios";

export const STORE_URL = "/nike/PartnerStore"
export const SELECT_URL = "/nike"
export function PartnerStore() {
  return axios.get(STORE_URL);
}

export function ChoseStore(storeNumber) {
  var body = {};
  var params = {
    params: {
      storeNumber: storeNumber,
    }
  }
  return axios.put(SELECT_URL, body, params)
}