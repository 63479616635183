import { createSlice } from "@reduxjs/toolkit";




const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  errorResponse: null,
  error: null,
  partners: [],
  AddingPartner: null,
  partnerToEdit: null,
  userToEdit: null,
  DeletedPartner: null,
  EditedPartner: null,
  users: [],
  usersCount: 0,
  NSPNames: [],
  partnersStore: null,
  AddingUser: null,
  UpdatedUser: null,
  DeletedUser: null,
  Countries:[]

};

export const callTypes = {
  list: "list",
  action: "action"
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorResponse = action.payload.error.response;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.errorResponse = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fetchedPartners: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload.partners;

      state.partners = entities;
      state.totalCount = totalCount;
    },
    fetchedUsers: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload;

      state.users = entities;
      state.totalCount = totalCount;
    },
    fetchNspNames: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.NSPNames = action.payload.NSPNames;
    },

    PartnerAdding: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.AddingPartner = action?.payload?.AddingPartner;
    },
    CleanAdding: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.AddingPartner = null;
      state.EditedPartner = null;
      state.DeletedPartner = null;
      state.partnerToEdit = null;
      state.userToEdit = null;
      state.UpdatedUser = null;
      state.AddingUser = null;
      state.DeletedUser = null;
    },
    AddPartnerToEdit: (state, action) => {
      state.userToEdit = action?.payload?.userToEdit;
    },
    EditedPartner: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.EditedPartner = action?.payload?.EditedPartner;
    },
    DeletedPartner: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.DeletedPartner = action?.payload?.DeletedPartner;
    },
    fetchedPartnerStores: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.partnersStore = action.payload.partnersStore;
    },
    UserAdded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.AddingUser = action?.payload?.AddingUser;
    },
    UpdatedUser: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.UpdatedUser = action?.payload?.UpdatedUser;
      state.UpdateMessage = "User Updated"
    },
    DeletedUser: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.DeletedUser = action?.payload?.DeletedUser;
      state.UpdateMessage = "User Deleted"
    },
    CountriesFetched: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.Countries = action?.payload?.Countries;
    }


  }
})