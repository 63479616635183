import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, formFeedback) => {
  const classes = ["form-control"];
  if (formFeedback && touched && errors) {
    classes.push("is-invalid");
  }

  if (formFeedback && touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  formFeedback = true,
  type = "text",
  ...props
}) {

  field.value = field.value || ''

  // if (errors.cesionarios) {
  //   try {
  //     field.name = field.name.split(".")
  //     return (
  //       <>
  //         {label && <label>{label}</label>}
  //         <input
  //           type={type}
  //           className={getFieldCSSClasses(touched[field.name[0]][field.name[1]][field.name[2]], errors[field.name[0]][field.name[1]][field.name[2]],formFeedback)}
  //           {...field}
  //           {...props}
  //         />
  //         {withFeedbackLabel && (
  //           <FieldFeedbackLabel
  //             error={errors[field.name[0]][field.name[1]][field.name[2]]}
  //             touched={touched[field.name[0]][field.name[1]][field.name[2]]}
  //             label={label}
  //             type={type}
  //             customFeedbackLabel={customFeedbackLabel}
  //           />
  //         )}
  //       </>
  //     );

  //   } catch (error) {
  //     return (
  //       <>
  //         {label && <label>{label}</label>}
  //         <input
  //           type={type}
  //           className={getFieldCSSClasses(touched[field.name], errors[field.name],formFeedback)}
  //           {...field}
  //           {...props}
  //         />
  //         {withFeedbackLabel && (
  //           <FieldFeedbackLabel
  //             error={errors[field.name]}
  //             touched={touched[field.name]}
  //             label={label}
  //             type={type}
  //             customFeedbackLabel={customFeedbackLabel}
  //           />
  //         )}
  //       </>
  //     );
  //   }

  // }
  return (
    <>
      {label && <label>{label}</label>}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name],formFeedback)}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
