import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { FormattedMessage } from "react-intl";




function ResetPassword(props) {

  const { intl } = props;
  const intl2Text = (id) => { return intl.formatMessage({ id: id }) };
  var search = window.location.search.substring(1);
  var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  var verificationtoken = "";
  if (params.token)
    verificationtoken = params.token

  var initialValues = {
    token: verificationtoken,
    password: "",
    ConfirmPassword: ""
  }
  const [msgOk, setMsgOk] = useState("")

  const [isRequested, setIsRequested] = useState(false);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const ResetPasswordSchema = Yup.object().shape({
    token: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "La contraseña debe contener al menos una mayúscula, un número y un carácter especial !@#$%^&*()-_=+{};:,<.>")
      .min(6, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "6")
      .max(15, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "15")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    ConfirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas no coinciden"
        ),
      }),
  });


  const formik = useFormik({

    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      resetPassword(values)
        .then(({ data: { message } }) => {
          setMsgOk("Restablecimiento de contraseña exitoso, ahora puede iniciar sesión");
          formik.resetForm();
          setIsRequested(false);
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },

  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1 mb-5">Restablecer Password</h3>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp text-center"
            >
              {!msgOk &&
                <>
                  {/* begin: Password */}
                  <div div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder="Password"
                      type="password"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "password"
                      )}`}
                      name="password"
                      {...formik.getFieldProps("password")}
                    />

                    {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.password}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* end: Password */}

                  {/* begin: Confirm Password */}
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder="Confirme Password"
                      type="password"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "ConfirmPassword"
                      )}`}
                      name="ConfirmPassword"
                      {...formik.getFieldProps("ConfirmPassword")}
                    />
                    {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.ConfirmPassword}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* end: Confirm Password */}
                </>}
              {!(msgOk !== "" || formik.status) &&
                <button
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 mt-5"
                  disabled={(!formik.dirty && formik.isValid) || (formik.dirty && !formik.isValid)}
                >
                  Restablecer
                </button>
              }

              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}

              {/* begin: Alert */}
              {msgOk && (
                <>
                  <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                    <div className="alert-text font-weight-bold">{msgOk}</div>
                  </div>
                  <Link
                    to="/auth/login"
                    className="font-weight-bold ml-2 text-hover-primary my-3 mr-2"
                    id="kt_login_forgot"
                  >
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                  </Link>
                </>
              )}
              {/* end: Alert */}
            </form>
          </div>
        </div >
      )
      }
    </>
  )

}


export default injectIntl(connect(null, auth.actions)(ResetPassword));