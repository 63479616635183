/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { /*useCallback*/ } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_nikeNsp/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import StartSettingsPage from "./modules/StartSettings/StartSettingsPage";
export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const location = useLocation();
  // const { id } = useParams();
  const [initialRoute, setInitialRoute] = useState("/")
  useEffect(() => {
    if (location && location.pathname.includes("/auth")) {
      setInitialRoute("/StartSettings/StoreSelect")
    } else {
      setInitialRoute("/")
    }
  }, [location])

  return (
    <Switch>

      {(!isAuthorized) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/

        <Redirect from="/auth" to={initialRoute} />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/StartSettings" component={StartSettingsPage} />

      <Layout>
        <BasePage />
      </Layout>
    </Switch>
  );
}
