/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_nikeNsp/_helpers";
import { ContentRoute } from "../../../../_nikeNsp/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";
import SuccessRegistration from "./SuccessRegistration";
import "../../../../_nikeNsp/_assets/sass/pages/login/classic/login-1.scss";
// import {  useIntl } from "react-intl";

export function AuthPage() {
  // const {intl} = useIntl();
  // const getText = (id) => { return intl.formatMessage({ id: id }) }
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-white"
          id="kt_login"
          style={{
            // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-footer-matylde.jpg")})`,
          }}
        >
          {/*begin::Aside*/}

          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50 ">
                <FormattedMessage id="AUTH.LEGEND.NO_ACCOUND"/>
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2 text-secondary"
                id="kt_login_signup"
              >
                <FormattedMessage id="AUTH.LEGEND.SING_UP" />

              </Link>
            </div> */}
            {/*end::Content header*/}

            <Link to="/" className="mb-4">
              <img
                alt="Logo"
                className="max-h-110px"
                src={toAbsoluteUrl("/media/logos/Nike_logo_black.png")}
              />
            </Link>

            {/* begin::Content body */}
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <Switch>
                <ContentRoute
                  path="/auth/login"
                  component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/verify-email"
                  component={VerifyEmail}
                />
                <ContentRoute
                  path="/auth/change-password"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/Success-resgistration"
                  component={SuccessRegistration}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2023 Kubo Digital
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
