import { createSlice } from "@reduxjs/toolkit";

const initialTeamsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  teamForEdit: undefined,
  lastError: null,
  InfoPermission: null,
  errorResponse: null,
  teamAction: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const teamSlice = createSlice({
  name: "teams",
  initialState: initialTeamsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorResponse = action.payload.error.response;
      state.InfoPermission = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.InfoPermission = null;
      state.errorResponse = null;
      state.teamForEdit = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    cleanStates: (state, action) => {
      state.teamForEdit = null;
      state.errorResponse = null;
      state.errorType = null;
      state.error = null;
      state.teamAction = null;
      state.InfoPermission = null;
    },
    // getTeamById
    teamFetched: (state, action) => {
      state.actionsLoading = false;
      state.teamForEdit = action.payload.teamForEdit;
      state.error = null;
    },
    // findTeams
    teamsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCustomer
    teamCreated: (state, action) => {

      state.teamAction = "create"
      state.actionsLoading = false;
      state.error = null;
    },
    // updateCustomer
    teamUpdated: (state, action) => {
      state.teamAction = "update"
      state.error = null;
      state.actionsLoading = false;

    },
    // deleteCustomer
    teamDeleted: (state, action) => {
      state.teamAction = "delete"

      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCustomers
    teamsDeleted: (state, action) => {
      state.teamAction = "delete"

      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // customersUpdateState
    teamsRolUpdated: (state, action) => {
      state.teamAction = "update"

      state.actionsLoading = false;
      state.error = null;
      const { ids, rol } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.rol = rol;
        }
        return entity;
      });
    },
    teamsPermissionInfo: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.InfoPermission = action.payload.InfoPermission;
    },
    updateAllowedActions: (state, action) => {
      state.teamAction = "update"

      state.error = null;
      state.actionsLoading = false;
      state.updateAllowedActions = action.payload.updateAllowedActions;
    }
  }
});
