import React from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";


const getFieldCSSClasses = (touched, errors, formFeedback) => {
  const classes = ["form-control", "form-control-solid"];
  if (formFeedback && touched && errors) {
    classes.push("is-invalid");
  }

  if (formFeedback && touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Select({
  label,
  withFeedbackLabel = true,
  formFeedback = true,
  type = "select",
  customFeedbackLabel,
  children,
  ...props
}) {

  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <>
      <style>
        {`
        .was-validated .form-control:invalid,
        .form-control.is-invalid {
          border-color: #F64E60;
          background-position: right calc(1.375em + 0.325rem) center;
        }
        .was-validated .form-control:valid,
        .form-control.is-valid {
          border-color: #1BC5BD;
          background-position: right calc(1.375em + 0.325rem) center;
        }
        `}
      </style>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses(touched, error, formFeedback)}
        {...field}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
          type={type}
        />
      )}
    </>
  );
}
