import { createSlice } from "@reduxjs/toolkit";



const initialAdminPartnerDoorState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  lastError: null,
  errorResponse: null,
  error: null,
  partners: [],
  AddingPartner: null,
  partnerToEdit: null,
  DeletedPartner: null,
  EditedPartner: null,
  doorAdded: null,
  nspFetched: null,
  updatedDoor: null,
  deletedDoor: null,
  Countries:[]
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const adminPartnerDoorSlice = createSlice({
  name: "adminPartnerDoor",
  initialState: initialAdminPartnerDoorState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorResponse = action.payload.error.response;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.errorResponse = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fetchedPartners: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload.partners;

      state.partners = entities;
      state.totalCount = totalCount;
    },
    PartnerAdding: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.AddingPartner = action?.payload?.AddingPartner;
    },
    CleanAdding: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.AddingPartner = null;
      state.EditedPartner = null;
      state.DeletedPartner = null;
      state.partnerToEdit = null;
      state.doorAdded = null;
      state.updatedDoor = null;
      state.deletedDoor = null
    },
    AddPartnerToEdit: (state, action) => {
      state.partnerToEdit = action?.payload?.partnerToEdit;
    },
    EditedPartner: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.EditedPartner = action?.payload?.EditedPartner;
    },
    DeletedPartner: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.DeletedPartner = action?.payload?.DeletedPartner;
    },
    fetchedPartnerDoors: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload;

      state.entities = entities;
      state.totalCount = totalCount;
    },
    doorAdded: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.doorAdded = action?.payload?.doorAdded;
    },
    updatedDoor: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.updatedDoor = action?.payload?.updatedDoor;
    },
    deletedDoor: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.deletedDoor = action?.payload?.deletedDoor;
    },
    nspFetched: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.nspFetched = action?.payload?.nspFetched;
    },
    CountriesFetched: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.Countries = action?.payload?.Countries;
    }


  }
})