import React from "react";
import { useIntl } from "react-intl";



export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel
}) {
  const intl = useIntl();
  const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {

    if (touched && error) {
      return <div className="invalid-feedback">{error}</div>;
    }

    if (touched && !error && label) {
      return <div className="valid-feedback">{label + intl.formatMessage({ id: "AUTH.VALIDATION.VALID_FIELD" })}</div>;

    }

    return (
      <div className="feedback">
        {customFeedbackLabel && <>{customFeedbackLabel}</>}
        {!customFeedbackLabel && (
          <div className="invalid-feedback">{error}</div>
        )}
      </div>
    );
  };

  const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
    if (touched && error) {
      return <div className="invalid-feedback">{error}</div>;
    }

    return (
      <div className="feedback">
        {customFeedbackLabel && <>{customFeedbackLabel}</>}
        {!customFeedbackLabel && label && (
          <>
            {intl.formatMessage({ id: "FORM.SELECT" })} <b>{label}</b>
          </>
        )}
      </div>
    );
  };

  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "date":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "select":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "email":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
