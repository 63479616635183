import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_nikeNsp/layout";
import { useSelector, shallowEqual } from "react-redux";
// import { DocumentacionPage } from "./pages/DocumentacionPage";
// import { MyPage } from "./pages/MyPage";
// import { TokensDisponiblesPage } from "./pages/TokensDisponiblesPage";

const Start = lazy(() =>
  import("./modules/Start/StartPage")
)
const ScanQr = lazy(() =>
  import("./modules/ScanQr/ScanQrPage"))

// const UserProfilePage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );
const partnerSetUp = lazy(() =>
  import("./modules/PartnerSetUp/ParnetSetUpPage")
);
const Users = lazy(() =>
  import("./modules/Users/UsersPage")
);
const PromoAnalizerPage = lazy(() =>
  import("./modules/PromoAnalizer/PromoAnalizerPage"))

const partnerDoorSetUp = lazy(() =>
  import("./modules/PartnerDoorsetUp/ParnetDoorSetUpPage")
);

const changeLogPage = lazy(() =>
  import("./modules/ChangeLog/ChangeLogPage"))

  const promoSetUp = lazy(() =>
  import("./modules/RewardSetUp/RewardSetUpPage"))


export default function BasePage() {
  const { expiration } = useSelector(
    ({ auth }) => ({
      expiration: auth.expiration
    }),
    shallowEqual
  );

  const expiredLoggin = (expiration) => {
    if (expiration) {

      var loggedDate = new Date(expiration);
      const logged30Min = new Date(loggedDate.getTime());
      logged30Min.setMinutes(loggedDate.getMinutes() - 30);
      var timeNow = new Date();
      // Restar las fechas y obtener la diferencia en milisegundos

      return timeNow > logged30Min;
    }
    return true;
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {expiredLoggin(expiration) &&
          <Redirect to="/logout" />
        }
        {
          /* Redirect from root URL to /tokens-disponibles. */
          <Redirect exact from="/" to="/Start" />
        }
        <ContentRoute path="/Start" component={Start} />
        <Route path="/ScanQr" component={ScanQr} />
        <ContentRoute path="/PartnerSetUp" component={partnerSetUp} />
        <ContentRoute path="/Users" component={Users} />
        <Route path="/Analyzer" component={PromoAnalizerPage} />
        <Route path="/PartnerDoorSetUp" component={partnerDoorSetUp} />
        <Route path="/ChangeLog" component={changeLogPage} />
        <Route path="/PromoSetUp" component={promoSetUp} />

        {/* <Route path="/perfil-usuario" component={UserProfilePage} /> */}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
