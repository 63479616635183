import { createSlice } from "@reduxjs/toolkit";



const initialAdminPartnerState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  errorResponse: null,
  error: null,
  partners: [],
  AddingPartner: null,
  partnerToEdit: null,
  DeletedPartner: null,
  EditedPartner: null,
  Countries: [],
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const adminPartnerSlice = createSlice({
  name: "adminPartner",
  initialState: initialAdminPartnerState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorResponse = action.payload.error.response;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.errorResponse = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fetchedPartners: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload.partners;

      state.partners = entities;
      state.totalCount = totalCount;
    },
    PartnerAdding: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.AddingPartner = action?.payload?.AddingPartner;
    },
    CleanAdding: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.AddingPartner = null;
      state.EditedPartner = null;
      state.DeletedPartner = null;
      state.partnerToEdit = null;
    },
    AddPartnerToEdit: (state, action) => {
      state.partnerToEdit = action?.payload?.partnerToEdit;
    },
    EditedPartner: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.EditedPartner = action?.payload?.EditedPartner;
    },
    DeletedPartner: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.DeletedPartner = action?.payload?.DeletedPartner;
    },
    CountriesFetched: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.Countries = action?.payload?.Countries;
    }

  }
})