export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { token }
      } = store.getState();

      var baseUrl = "https://nspbackendproduction.azurewebsites.net/api";
      var location = window.location.href;
      if (!(location.includes("nspfrontendproduction") || location.includes("memberrewardstool"))) {
        baseUrl = 'https://nspbackend.azurewebsites.net/api';
      }
      config.baseURL = baseUrl
      config.timeout = 30000;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}
