import { createSlice } from "@reduxjs/toolkit";



const rewardList = [
  {
    id: "751800000220",
    name: "BOGO 30 % apparel"
  },
  {
    id: "751800000045",
    name: "20 % con compra STS"
  },
  {
    id: "7518000000221",
    name: "15 % Cumpleaños Nike"
  }
]

const intialScanQrState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  error: null,
  scanResult: null,
  redeemItem: null

}

export const callTypes = {
  list: "list",
  action: "action"
};


export const scanQrSlice = createSlice({
  name: "scanQr",
  initialState: intialScanQrState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {

      state.error = null;
      state.verification = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    cleanStates: (state, action) => {
      state.error = null;
      state.entities = null;
    },
    partnerUnlockFetched: (state, action) => {
      var result = action.payload;
      if (result.objects) {
        result.objects.forEach(item => {
          var reward = rewardList.find(s => s.id === item.partnerPromoId);
          if (reward) {
            item.reward = reward.name;
          }
        });
      }


      state.entities = result;
      state.listLoading = false;
    },
    redeemUnlockFetched: (state, action) => {
      state.redeemUnlock = action.payload;
      state.actionsLoading = false;
    },
    setRedeemItem: (state, action) => {
      state.redeemItem = action.payload;
    },
    setScanResult: (state, action) => {
      state.scanResult = action.payload;
    }
  }
})