import React, {  } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

function SuccessRegistration(props) {
  const msgOk = "Registro exitoso, revise su correo electrónico para obtener instrucciones de verificación";

  return (
    <>
      <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
        <div className="alert-text font-weight-bold">{msgOk}</div>
      </div>
      <Link
        to="/auth/login"
        className="font-weight-bold ml-2 text-hover-primary my-3 mr-2"
        id="kt_login_forgot"
      >
        <FormattedMessage id="AUTH.LOGIN.TITLE" />
      </Link>
    </>
  )
}


export default injectIntl(connect(null, auth.actions)(SuccessRegistration));