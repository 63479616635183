import React from "react";
import { PartnerStore, ChoseStore } from "../../_redux/StartCrud";
import { useEffect } from "react";
import { useState } from "react";
// import { Card, CardBody, CardHeader, CardHeaderToolbar, } from "../../../../../_nikeNsp/_partials/controls";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom"
import { useIntl } from "react-intl";

export default function StoreSelect() {
  const history = useHistory();


  const intl = useIntl();
  const getText = (id) => { return intl.formatMessage({ id: id }) }
  var textPage = {
    title: getText("STORES.TITLE"),
    subtitle: getText("STORES.SUBTITLE"),
    placeholder: getText("STORES.PLACEHOLDER"),
    confirm: getText("BUTTON.COMFIRM")
  }
  const [stores, setStores] = useState([])
  useEffect(() => {
    PartnerStore().then((response) => {
      if (response.data) {
        var { partnersStore } = response.data
        setStores(partnersStore)
      }
    })
  }, [])



  const [storeOptions, setStoreOptions] = useState(<></>)

  useEffect(() => {
    setStoreOptions(
      stores.map((store, index) => {
        return (
          <option
            key={index}
            value={store.storeNumber}
          >
            {store.storeName}
          </option>
        )
      })
    )
  }, [stores])

  const [storeNumber, setStoreNumber] = useState(null);
  function changeStoreNumber(e) {
    setStoreNumber(e.target.value)
  }
  function choseStore() {
    ChoseStore(storeNumber)
      .then(history.push("/"))
  }




  return (
    <>
      <style>
        {`

      .form-control {
          color: #000000;
          background-color: #ffffff;
          border-color: #202021;
          outline: 0;
      }
      `
          //   .form - control option:hover {
          //   background - color: white;
          // color: black;
          // }

          // .form-control option:checked {
          //   background - color: black;
          // color: white;
          // }
        }
      </style>
      <div>

        <div className="text-center mb-10">
          <h3 className="font-size-h1">
            {textPage.title}
          </h3>
          <p className="text-muted font-weight-bold">
            {textPage.subtitle}
          </p>
        </div>
        <div className="d-flex flex-column align-items-center">

          {/* <h5 style={{ "paddingTop": "100px" }}>{textPage.subtitle}</h5> */}

          <div className="col-lg-12">

            <select
              className="form-control"
              name="status"
              placeholder="Filter by Status"
              // TODO: Change this code
              onChange={changeStoreNumber}
            >

              <option
                value=""
              >
                {textPage.placeholder}
              </option>
              {storeOptions}
            </select>
          </div>
          <br />
          <Link to="#">
            <Button
              onClick={choseStore}
              className="btn btn-dark "
              disabled={!storeNumber}
            >
              {textPage.confirm}
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}