import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
// import { useIntl } from "react-intl";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, hideFeedBack) => {
  const classes = ["form-control"];
  if (!hideFeedBack) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }
  return classes.join(" ");
};


export function DatePickerField({ ...props }) {
  const { customFeedbackLabel, label } = props
  // const intl = useIntl();
  const { setFieldValue, errors, touched, handleSubmit } = useFormikContext();
  const [field] = useField(props);


  var placeHolder = "dd/mm/yyyy";
  if (props.placeHolder) {
    placeHolder = props.placeHolder;
  }
  const { hideFeedBack = false, hideFeedBackLabel = false, onChangeSubmit = false } = props;

  return (
    <>
      <style>
        {`
          .react-datepicker-wrapper {
          width: 100%;
        }
      `}
      </style>
      {props.label && <label>{props.label}</label>}
      <br />
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name], hideFeedBack)}
        // style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value))}
        onChange={(val) => {
          setFieldValue(field.name, val);
          if (onChangeSubmit)
            handleSubmit()
        }}
        dateFormat={"dd/MM/yyyy"}
        placeholderText={placeHolder}
      />
      {!hideFeedBackLabel &&
        // <>
        //   {errors[field.name] && touched[field.name] && props.label ? (
        //     <div className="invalid-feedback">
        //       {errors[field.name].toString()}
        //     </div>
        //   ) : (
        //     (props.label === intl.formatMessage({ id: "FORM.CREATED" }) &&
        //       <div className="valid-feedback">
        //         {intl.formatMessage({ id: "FORM.DATE_CREATE" })} <b>{props.label}</b>
        //       </div>
        //     )
        //   )}
        // </>
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={"date"}
          customFeedbackLabel={customFeedbackLabel}
        />
      }
    </>
  );
}
