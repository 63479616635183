/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

// import {useSubheader} from "../../../_core/MatyldeSubheader";


export function HeaderMenu({ layoutProps }) {

    // const subheader = useSubheader();


    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <div className="d-flex align-items-center flex-wrap mr-1">

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5">
                <>
                  {/* {subheader.title} */}
                </>

              </h5>

            </div>
        </div>
        {/*end::Header Nav*/}
    </div>;
}
