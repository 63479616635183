/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/NikeLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
// import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

// import {Link} from "react-router-dom";

export function QuickActionsDropdown() {

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.quick-actions.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
              id="kt_quick_actions_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          {/* <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_actions_panel_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="quick-actions-tooltip">Analíticass</Tooltip>
              }
            >
              <Link to="/">
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                    />
                  </span>
                </div>
              </Link>
            </OverlayTrigger>
          </Dropdown.Toggle> */}


        </Dropdown>
      )}
    </>
  );
}
