import React from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar, } from "../../../../../_nikeNsp/_partials/controls";
import { useIntl } from "react-intl";
export default function Development() {
  //#region TEXT
  const intl = useIntl();
  const getText = (id) => { return intl.formatMessage({ id: id }) }
  var textPage = {
    title: getText("DEVELOPMENT.TITLE"),
    subtitle: getText("DEVELOPMENT.SUBTITLE"),
  }


  return (
    <Card>
      <CardHeader title={textPage.title}>
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody
        className="d-flex flex-column align-items-center"
        style={{ "height": "500px" }}
      >
        <h3 style={{ "paddingTop": "200px" }}>{textPage.subtitle}</h3>
        <br></br>
        <br></br>
        <br />
        {/* <Link to="#">
          <Button
            className="btn btn-dark"
            disabled={actionsLoading}
            onClick={redeem}
          >
            {actionsLoading &&
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            }

            {textPage.confirm}
          </Button>
        </Link> */}
      </CardBody>
    </Card>
  )
}