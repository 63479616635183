import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  error: null,
  scanResult: null,
  redeemItem: null,
  storeList: null,
  partnersStore: null,
  redeemsCount: 0,
  redeemsList: [],
  report: null,

  ChangeLogList: [],
  ChangeLogCount: 0,

  rewardList: [],
  rewardCount: 0,

  rewardToEdit: null,
  rewardAdded: null,
  rewardUpdated: null,
  Countries: [],
  reportReward: null
}

export const callTypes = {
  list: "list",
  action: "action"
};

export const promoAnalizerSlice = createSlice({
  name: "promoAnalizer",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorResponse = action.payload.error.response;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.errorResponse = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    CleanAdding: (state, action) => {

      state.listLoading = false;
      state.actionsLoading = false;
      state.entities = null;
      state.error = null;
      state.scanResult = null;
      state.redeemItem = null;
      state.storeList = null;
      state.partnersStore = null;
      state.redeemsCount = 0;
      state.report = null;
      state.rewardToEdit = null;
      state.rewardAdded = null;
      state.rewardUpdated = null;
      state.reportReward = null;

    },
    CleanReports: (state, action) => {
      state.actionsLoading = false;
      state.report = null;
      state.reportReward = null;

    },
    fetchedPartnerStores: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.partnersStore = action.payload.partnersStore;
    },
    fetchRedeemAnalizer: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload.redeemsList;

      state.redeemsList = entities;
      state.redeemsCount = totalCount;
    },
    fetchChangeLog: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload.changeLog;

      state.ChangeLogList = entities;
      state.ChangeLogCount = totalCount;
    },
    fetchRedeemReport: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.report = action.payload.report;
    },

    fetchRewards: (state, action) => {
      state.listLoading = false;
      state.error = null;
      var { entities, totalCount } = action.payload.fetchRewards;

      state.rewardList = entities;
      state.rewardCount = totalCount;
    },
    rewardToEdit: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rewardToEdit = action.payload.rewardToEdit
    },
    rewardAdded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rewardAdded = action.payload?.rewardAdded
    },
    rewardUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.rewardUpdated = action.payload?.rewardUpdated
    },
    CountriesFetched: (state, action) => {
      state.actionsLoading = false;
      state.errorResponse = null;
      state.Countries = action?.payload?.Countries;
    },
    fetchRewardReport: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.reportReward = action.payload.reportReward;
    },

  }
})