//JSON CONTENEDOR DE TODA LA INFO
import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { teamSlice } from "../app/modules/UserProfile/Team/_redux/teamSlice";
import { tokensSlice } from "../app/modules/UserProfile/Tokens/_redux/tokensSlice";
import { scanQrSlice } from "../app/modules/ScanQr/_redux/scanQrslice";
import { adminPartnerDoorSlice } from "../app/modules/PartnerDoorsetUp/_redux/adminPartnerSlice";
import { adminPartnerSlice } from "../app/modules/PartnerSetUp/_redux/adminPartnerSlice";
import { promoAnalizerSlice } from "../app/modules/PromoAnalizer/_redux/promoAnalizerSlice";
import { usersSlice } from "../app/modules/Users/_redux/usersSlice";
export const rootReducer = combineReducers({
  auth: auth.reducer, //aquí inserto user y token
  teams: teamSlice.reducer,
  tokens: tokensSlice.reducer,
  scanQr: scanQrSlice.reducer,
  adminPartners: adminPartnerSlice.reducer,
  analizer: promoAnalizerSlice.reducer,
  users:usersSlice.reducer,
  partnerDoor:adminPartnerDoorSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
