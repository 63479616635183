import { createSlice } from "@reduxjs/toolkit";

const initialTokensState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  tokensForEdit: undefined,
  lastError: null,
  finalSignatures: null,
  isUnathorized: null,
  InfoPermission: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: initialTokensState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      try {
        state.isUnathorized = action.payload.error.response.data.message === "Unauthorized";
      }
      catch (error) {
        state.isUnathorized = null;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    tokensFetched: (state, action) => {
      state.actionsLoading = false;
      state.tokensForEdit = action.payload.tokensForEdit;
      state.error = null;
    },
    // createCustomer
    tokensCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.customer);
    },
    // updateCustomer
    tokensUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.customer.id) {
          return action.payload.customer;
        }

        return entity;
      });
    },
    // deleteCustomer
    tokensDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCustomers
    tokenssDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // customersUpdateState
    tokenssStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    finalSignatures: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { finalSignatures } = action.payload;
      state.finalSignatures = finalSignatures;
    },
    teamsMyPermissionInfo: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.InfoPermission = action.payload.InfoPermission;
    },

  }
});
