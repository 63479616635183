import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { Redirect } from "react-router-dom";

const initialValues = {
  name: "",
  lastname: "",
  email: "",
  telefono: "",
  company: "",
  cargo: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};
var msgOk = "";

function Registration(props) {
  const { intl } = props;
  const intl2Text = (id) => { return intl.formatMessage({ id: id }) };

  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
      .max(50, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "50")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastname: Yup.string()
      .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
      .max(50, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "50")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
      .max(50, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "50")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // telefono: Yup.string()
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
    //   .max(15, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "15")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    // company: Yup.string()
    //   .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
    //   .max(50, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "50")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    // Id_agrarian_group: Yup.string()
    //   .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
    //   .max(50, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "50")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    // Id_District: Yup.string()
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    // cargo: Yup.string()
    //   .min(3, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "3")
    //   .max(50, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "50")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    password: Yup.string()
      .matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "La contraseña debe contener al menos una mayúscula, un número y un carácter especial !@#$%^&*()-_=+{};:,<.>")
      .min(6, intl2Text("AUTH.VALIDATION.MIN_LENGTH_FIELD") + "6")
      .max(15, intl2Text("AUTH.VALIDATION.MAX_LENGTH_FIELD") + "15")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas no coinciden"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "Debe aceptar los Términos y Condiciones"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [registrationOk, setResgistrationOk] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();

      register(values.name, values.lastname, values.email, values.password, values.changepassword, values.acceptTerms, values.telefono, values.company, values.cargo, values.Id_agrarian_group, parseInt(values.Id_District))
        .then(({ data: { message } }) => {
          //props.register(accessToken);
          msgOk = "Successful record, check your email to obtain verification instructions";
          formik.resetForm();
          disableLoading();
          setSubmitting(false);
          setResgistrationOk(true);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.response.data.tipo === 1) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN_EMAIL",
              })
            );
          }
          else if (error.response.data.tipo === 2) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_AGRARIAN",
              })
            );
          } else {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          }
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {registrationOk && <Redirect to="/auth/Success-resgistration" />}
      <div className="text-center mb-10">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your data to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {msgOk && (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text font-weight-bold">{msgOk}</div>
          </div>
        )}
        {/* end: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Name */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Name */}

        {/* begin: Lastname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Surname"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastname"
            )}`}
            name="lastname"
            {...formik.getFieldProps("lastname")}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Lastname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="E-mail"
            type="email"
            autoComplete="off"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Telefono */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Phone"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "telefono"
            )}`}
            name="telefono"
            {...formik.getFieldProps("telefono")}
          />
          {formik.touched.telefono && formik.errors.telefono ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.telefono}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Telefono */}


        {/* begin: Company
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Nombre de la empresa"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "company"
            )}`}
            name="company"
            {...formik.getFieldProps("company")}
          />
          {formik.touched.company && formik.errors.company ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.company}</div>
            </div>
          ) : null}
        </div>
         end: Company */}
        {/* begin: Clave */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Clave nucleo agrario"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "Id_agrarian_group"
            )}`}
            name="Id_agrarian_group"
            {...formik.getFieldProps("Id_agrarian_group")}
          />
          {formik.touched.Id_agrarian_group && formik.errors.Id_agrarian_group ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.Id_agrarian_group}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Clave */}
        {/* begin: Cargo */}
        {/* <div className="form-group fv-plugins-icon-container">
          <select
            placeholder="Distrito"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "distrito"
            )}`}
            name="Id_District"
            {...formik.getFieldProps("Id_District")}
          >
            <option value="">Seleccione su Distrito</option>
            <option value="1" >Acatlán de Pérez Figueroa </option>
            <option value="2" >San Juan Bautista Tuxtepec</option>
            <option value="3" >Loma Bonita</option>
            <option value="4" >Teotitlán de Flores Magón</option>
            <option value="5" >Asunción Nochixtlán</option>
            <option value="6" >Heroica Ciudad de Huajuapan de León</option>
            <option value="7" >Putla Villa de Guerrero</option>
            <option value="8" >Heroica Ciudad de Tlaxiaco</option>
            <option value="9" >Ixtlán de Juárez</option>
            <option value="10" >San Pedro y San Pablo Ayutla</option>
            <option value="11" >Matías Romero Avendaño</option>
            <option value="12" >Santa Lucia del Camino</option>
            <option value="13" >Oaxaca de Juárez Zona Sur</option>
            <option value="14" >Oaxaca de Juárez Zona Norte</option>
            <option value="15" >Santa Cruz Xoxocotlán</option>
            <option value="16" >Zimatlán de Álvarez</option>
            <option value="17" >Tlacolula de Matamoros</option>
            <option value="18" >Santo Domingo Tehuantepec</option>
            <option value="19" >Salina Cruz</option>
            <option value="20" >Juchitán de Zaragoza</option>
            <option value="21" >Ejutla de Crespo</option>
            <option value="22" >Santiago Pinotepa Nacional</option>
            <option value="23" >San Pedro Mixtepec</option>
            <option value="24" >Miahuatlán de Porfirio Díaz</option>
            <option value="25" >San Pedro Pochutla</option>
          </select>
          {formik.touched.Id_District && formik.errors.Id_District ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.Id_District}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Cargo */}
        {/* begin: Cargo */}
        {/* <div className="form-group fv-plugins-icon-container">
          <select
            placeholder="Cargo"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "cargo"
            )}`}
            name="cargo"
            {...formik.getFieldProps("cargo")}
          >
            <option value="">Seleccione su cargo...</option>
            <option value="Presidente">Presidente</option>
            <option value="Secretario">Secretario</option>
            <option value="Tesorero">Tesorero</option>
            <option value="Presidente de consejo de Vigilancia">Presidente de consejo de Vigilancia</option>
            <option value="Administracion">Administracion</option>
          </select>
          {formik.touched.cargo && formik.errors.cargo ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.cargo}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Cargo */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            autoComplete="off"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="Password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1 text-secondary"
              rel="noopener noreferrer"
            >
              I accept the terms and conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">


          <Link to="/auth/login">
            <button
              type="button"
              className="btn  btn-outline-dark font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-dark font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Sign up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
