import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_nikeNsp/layout";
import StoreSelect from "./pages/StoreSelect/StoreSelect";
import Start from "./pages/Index/Index";
import Development from "./pages/Development/Development";
import { Link, } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_nikeNsp/_helpers";
export default function StartSettingsPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-white"
          id="kt_login"
          style={{
            // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-footer-matylde.jpg")})`,
          }}
        >
          {/*begin::Aside*/}

          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <Link to="#" className="mb-4">
              <img
                alt="Logo"
                className="max-h-110px"
                src={toAbsoluteUrl("/media/logos/Nike_logo_black.png")}
              />
            </Link>

            {/* begin::Content body */}
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <Suspense fallback={<LayoutSplashScreen />}>
                {/* <Redirect></Redirect> */}
                <Switch>


                  <Redirect
                    exact={true}
                    from="/StartSettings"
                    to="/StartSettings/Index"
                  />

                  <ContentRoute path="/StartSettings/Index" component={Start}></ContentRoute>
                  <ContentRoute path="/StartSettings/StoreSelect" component={StoreSelect} />
                  <ContentRoute path="/StartSettings/NoAvaiable" component={Development} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
